
import CustomTableN from '@/core/components/shared/CustomTableN.vue';
import { TableOptions } from '@/core/domain/TableOptions';
import { Bulk } from '@/wms/domain/bulk/Bulk';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SerialModal from '@/wms/infrastructure/ui/reference/modals/SerialModal.vue';

@Component({
  name: 'PickedBulksList',
  components: {
    CustomTableN,
    SerialModal
  }
})
export default class PickedBulksList extends Vue {
  @Prop({ type: Array, required: true, default: () => [] }) items!: Bulk[];
  @Prop({ type: Object, required: true, default: () => new PreRegister() }) form!: PreRegister;
  @Prop() actions:
    | {
        customActions: {
          label: string;
          icon: string;
          action: (item: Bulk) => void;
          condition: (item: Bulk) => boolean;
        }[];
      }
    | undefined;
  @Prop({ required: true }) readonly warehouseProcess!: 'outbound' | 'inbound' | '';

  bulkSelected = [];

  get localBulks(): Bulk[] {
    return this.items;
  }

  get fields(): TableOptions[] {
    return [
      // {
      //   label: `${this.$t('general.selected')}`,
      //   field: 'selected'
      // },
      {
        label: `#`,
        field: 'number'
      },
      {
        label: `${this.$t('general.reference')}`,
        field: 'reference',
        formatFn: (item: Bulk) => (item ? `${item?.reference?.code} - ${item.reference?.filterBrand}` : '-')
      },
      {
        label: `Serial`,
        field: 'serial',
        formatFn: (item: Bulk) => (!item.serial ? 'No generado' : item.serial)
      },
      {
        label: `${this.$t('general.description')}`,
        field: 'shippingDetails',
        formatFn: (item: Bulk) => (item ? item?.shippingDetails?.productName : '-')
      },
      {
        label: `${this.$t('general.commodity')}`,
        field: 'shippingDetails.commodity.description',
        formatFn: (item: Bulk) => (item ? item?.shippingDetails?.commodity?.description : '-')
      },
      {
        label: `${this.$t('general.location')}`,
        field: 'location',
        formatFn: (item: Bulk) =>
          item.codeLocationPk
            ? `${item.storeName} - ${item.zoneName} - ${item.rackName} - ${item.rackLevel} - ${item.position}`
            : '-'
      },
      {
        label: `${this.$t('general.dimentions')}`,
        field: 'dimentions',
        formatFn: (row: Bulk) => `${row.length}x${row.width}x${row.height}`
      },
      {
        label: `${this.$t('general.weight')}`,
        field: 'weight'
      },
      {
        label: `${this.$t('general.weightUnit')}`,
        field: 'weightUnit',
        formatFn: (item: Bulk) => {
          return item.weightUnit ? item.weightUnit.description : `-`;
        }
      },
      {
        label: `${this.$t('general.packaging')}`,
        field: 'packaging',
        formatFn: (item: Bulk) => {
          if (item?.containerType) return item.containerType?.description;
          return item.packaging
            ? item.packaging?.unit?.description
            : item.packingUnit
            ? item.packingUnit.description
            : '-';
        }
      },
      {
        label: `${this.$t('general.type')}`,
        field: 'quotationTypeLine'
      },
      {
        label: `${this.$t('general.containerType')}`,
        field: 'containerType',
        formatFn: (item: Bulk) => {
          return item.dispatchContainer ? item.dispatchContainer.description : '-';
        }
      },
      {
        label: `${this.$t('general.owner')}`,
        field: 'owner.fullName',
        formatFn: (item: Bulk) => {
          return item.owner ? item.owner.fullName : '-';
        }
      },
      {
        label: `${this.$t('general.cargoTo')}`,
        field: 'cargoTo.fullName',
        formatFn: (item: Bulk) => {
          return item.cargoTo ? item.cargoTo.fullName : '-';
        }
      },
      {
        label: `BL`,
        field: 'billOfLanding',
        formatFn: (item: Bulk) => {
          return item.houseBillOfLanding ? item.houseBillOfLanding.serial : '-';
        }
      },
      {
        label: `Actions`,
        field: 'actions'
      }
    ];
  }

  renderFunction(field: TableOptions, item: Bulk) {
    if (field.formatFn) {
      return `${field.formatFn(item)}`;
    }

    return `${item[field.field as keyof Bulk]}`;
  }

  showModal() {
    this.$bvModal.show('mdlSerialModalBulk');
  }

  selectBulk(item: any) {
    this.bulkSelected = item;
    // eslint-disable-next-line
    console.log(item)
  }
}

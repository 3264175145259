
import Vue from 'vue';
import { BCard } from 'bootstrap-vue';
import Component from 'vue-class-component';
import VueBarcode from 'vue-barcode';
import QrcodeVue from 'qrcode.vue';
import { Prop } from 'vue-property-decorator';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { Bulk } from '@/wms/domain/bulk/Bulk';

@Component({
  name: 'CargoTag',
  components: { BCard, VueBarcode, QrcodeVue }
})
export default class CargoTag extends Vue {
  @Prop({ required: true }) form!: PreRegister;
  @Prop({ required: true }) bulk!: Bulk;

  shipper = {
    name: 'Nacho',
    number: '5559847584',
    company: 'TIENDANUBE',
    address: ' Isabel la Catolica 495 Algarin',
    location: '06880 Cuauhtemoc DF'
  };
  consignee = {
    name: 'Chino',
    number: '9854471254',
    company: 'EMPRESAEJEMPLO',
    address: ' Isabel la Catolica 777 Algarin',
    location: '06880 Cuauhtemoc CDMX'
  };
  shipment = {
    routeCode: 'MEX-015-9-82',
    content: 'Paqueteria General',
    trackingNumber: '1Z 979 VY1 04 1280 5419',
    lotNumber: '12543',
    refNumber: 'ABC546',
    date: '2023-08-21',
    weight: '500Kg',
    instructions: 'Leave with receptionist',
    description: 'Boxes with toys'
  };
}
